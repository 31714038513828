import {
  HOMEPAGE_VIDEO,
  HOMEPAGE_VISIBLE_PORTFOLIO,
  HomepageHeader,
  Playbook,
  Portfolio,
} from '@r-client/republic-capital/feature/static-homepage';
import { Layout } from '@r-client/republic-capital/feature/static-layout';

import { SEOMetaTags } from '../components/seo-meta-tags/seo-meta-tags';

function HomePage() {
  return (
    <>
      <SEOMetaTags />
      <Layout variant="homepage">
        <HomepageHeader video={HOMEPAGE_VIDEO} />

        <Playbook />
        <Portfolio portfolio={HOMEPAGE_VISIBLE_PORTFOLIO} />
      </Layout>
    </>
  );
}

export default HomePage;
