import arbol from './images/arbol.png';
import arbolLogo from './images/arbol-logo.png';
import carta from './images/carta.png';
import cartLogo from './images/carta-logo.png';
import firefly from './images/firefly.png';
import fireflyLogo from './images/firefly-logo.png';
import gotenna from './images/gotenna.png';
import gotennaLogo from './images/gotenna-logo.png';
import gunzilla from './images/gunzilla.png';
import gunzillaLogo from './images/gunzilla-logo.png';
import k2Space from './images/k2-space.png';
import k2SpaceLogo from './images/k2-space-logo.png';
import petra from './images/petra.png';
import petraLogo from './images/petra-logo.png';
import skyryse from './images/skyryse.png';
import skyryseLogo from './images/skyryse-logo.png';
import spacePerspective from './images/space-perspective.png';
import spacePerspectiveLogo from './images/space-perspective-logo.png'; //  using temp video URL before deciding CDN provider

export var HOMEPAGE_VIDEO = 'https://static-republic-capital.sfo3.digitaloceanspaces.com/bg.mp4';
var HOMEPAGE_PORTFOLIO = [{
  image: firefly,
  logo: fireflyLogo,
  video: 'https://static-republic-capital.sfo3.digitaloceanspaces.com/FLIGHT%202%20THE%20BLACK.mp4',
  link: 'https://fireflyspace.com/'
}, {
  image: spacePerspective,
  logo: spacePerspectiveLogo,
  video: 'https://drive.google.com/uc?export=download&id=1Rly8Bz8WbfYu88qQfOjadAU3FCihSrag',
  link: 'https://spaceperspective.com/'
}, {
  image: gotenna,
  logo: gotennaLogo,
  video: 'https://drive.google.com/uc?export=download&id=1mqFW1lfJxT2ZS-kxW6Bfjvk3kB6fdTEF',
  link: 'https://gotenna.com/'
}, {
  image: skyryse,
  logo: skyryseLogo,
  video: 'https://static-republic-capital.sfo3.digitaloceanspaces.com/Skyryse%20FlightOS%20Empowering%20Anyone%20to%20Fly.mp4',
  link: 'https://skyryse.com/',
  hide: true
}, {
  image: arbol,
  logo: arbolLogo,
  video: 'https://static-republic-capital.sfo3.digitaloceanspaces.com/arbol.mp4',
  link: 'https://www.arbol.io/',
  hide: true
}, {
  image: carta,
  logo: cartLogo,
  video: 'https://static-republic-capital.sfo3.digitaloceanspaces.com/Carta%20Launch_%20Free%20startup%20fundraising%20&%20cap%20table%20software%20for%20founders.mp4',
  link: 'https://carta.com/',
  hide: true
}, {
  image: gunzilla,
  logo: gunzillaLogo,
  video: 'https://static-republic-capital.sfo3.digitaloceanspaces.com/gunzilla.mp4',
  link: 'https://gunzillagames.com/en/'
}, {
  image: k2Space,
  logo: k2SpaceLogo,
  video: 'https://drive.google.com/uc?export=download&id=1q-Hs0PFHVvROdbYSFatyU-CdO5QDhwVr',
  link: 'https://www.k2space.com/'
}, {
  image: petra,
  logo: petraLogo,
  video: 'https://static-republic-capital.sfo3.digitaloceanspaces.com/petra.mp4',
  link: 'https://petra.cc/'
}];
export var HOMEPAGE_VISIBLE_PORTFOLIO = HOMEPAGE_PORTFOLIO.filter(function (company) {
  return !company.hide;
});